@import './styles/videojs.scss';

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=1949bda6-31fb-4516-9e65-8ce0ade390b2&fontids=1564433,1564457,5188101,5203050,5338781");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');


body {
  background-color: #000;
  position: relative;
  overflow-y: scroll;
  margin: 0px;
  padding: 0px;
}
h1, h2, h3, h4{
  margin: 0px;
  padding: 0px;
}


// BASE










// AR

#helper {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  font-size: x-large;
  color: white;
  font-family: Poppins;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  button{
    margin: 0 auto;
  }
  *{
    line-height: normal;
  }
}

#head {

  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  font-size: x-large;
  color: white;
  font-family: Poppins;
  font-weight: bold;
  letter-spacing: 20px;
  margin-left: 10px;
  white-space: nowrap;
  z-index: 10;
}

#camera {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  object-fit: cover;
}
#ar-switch{
  position: fixed;
  bottom: 30px;
  right: 30px;
  color:white;
  z-index: 10;
  padding: 5px 10px;
  border: solid 1px white;
  border-radius: 10px;
  cursor: pointer;
}

#button-record{
  background: #BE2D33;
  border-radius: 25px;
  padding: 10px 60px;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: white;
  font-size: 1.2em;
  font-family: Poppins;
  cursor: pointer;
}

.a-enter-vr,.a-enter-ar{
  display: none !important;
}

.immersif-page{
  footer{
    display:none;
  }
}

//GYROSCOPE ACCESSOR STYLES
.a-dialog-allow-button {
  background-color: #333;
  color: white;
}

.a-dialog-deny-button {
  background-color: #BE2D33;
  color: white;
}

.a-dialog-ok-button {
  background-color: #333;
  color: white;
 }

 .a-loader-title{
   display: none !important;
 }

 .play-again{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:white;
  border: solid 1px white;
  padding: 10px;
  border-radius: 10px;
  background: rgba(0,0,0,0.7);
  display: none;
 }
 #end-buttons{
   position: absolute;
   left: 50%;
   bottom: 20px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   transform: translate(-50%, -100%);
   display: none;
   >*{
     width: 100%;
     display: flex;
     justify-content: center;
   }
 }